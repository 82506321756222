import React, { useState, useEffect } from "react";
import './header.css';
import { HiOutlineHome, HiOutlineClipboardList, HiX, HiOutlineMenu, HiOutlineTerminal } from "react-icons/hi";

const Header = () => {
    const [toggle, setToggle] = useState(false);
    const [activeNav, setActiveNav] = useState("#home");
    const [logoText, setLogoText] = useState("AK");
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleMouseEnter = () => !isMobile && setLogoText("Arun K Nair");
    const handleMouseLeave = () => !isMobile && setLogoText("AK");

    useEffect(() => {
        const handleScroll = () => {
            const header = document.querySelector(".header");
            if (window.scrollY >= 80) header.classList.add("scroll-header");
            else header.classList.remove("scroll-header");
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const handleLinuxVersion = () => {
        window.open('https://akn414-ind.github.io/Portfolio_Linux/', '_blank');
    };

    return (
        <header className="header">
            <nav className="nav container">
                <a href="/" className="nav__logo" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                    {logoText}
                </a>
                {!isMobile && (
                    <>
                        <div className={toggle ? "nav__menu show-menu" : "nav__menu"}>
                            <ul className="nav__list grid">
                                <li className="nav__item">
                                    <a href="/" onClick={() => setActiveNav("#home")} 
                                    className={activeNav === "/home" ? "nav__link active-link" : "nav__link"}>
                                        <HiOutlineHome className="nav__icon"/>Home
                                    </a>
                                </li>
                                <li className="nav__item">
                                    <a href="/projects" onClick={() => setActiveNav("#projects")} 
                                    className={activeNav === "/projects" ? "nav__link active-link" : "nav__link"}>
                                        <HiOutlineClipboardList className="nav__icon"/>Projects
                                    </a>
                                </li>
                                <li className="nav__item">
                                    <button 
                                        onClick={handleLinuxVersion}
                                        className="nav__link nav__link--linux"
                                    >
                                        <HiOutlineTerminal className="nav__icon"/>OS Mode
                                    </button>
                                </li>
                            </ul>
                            <HiX className="nav__close" onClick={() => setToggle(!toggle)} />
                        </div>
                        <div className="nav__toggle" onClick={() => setToggle(!toggle)}>
                            <HiOutlineMenu />
                        </div>
                    </>
                )}
            </nav>
        </header>
    );
}

export default Header;